<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="order-accept" :query="{}">
      <DefaultBtn
        titleButton="Aprovar Pedido"
        icon="edit"
        @click="updateOrderSolicitation"
      />
    </Mural>
    <form>
      <div class="content-new-training flex row col-12 q-pa-md">
        <div class="col-6">
          <div class="form-input col-12 column">
            <div class="row">
              <span class="title-input-form">Nome</span>
            </div>
            <div class="row">
              <q-input
                class="col-10"
                bg-color="grey-3"
                v-model="order.name"
                :disable="true"
                color="default-pink"
                dense
                type="text"
                outlined
              />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-input col-12 column">
            <div class="row">
              <span class="title-input-form">Produto</span>
            </div>
            <div class="row">
              <q-input
                class="col-10"
                bg-color="grey-3"
                v-model="order.name_product"
                :disable="true"
                color="default-pink"
                dense
                type="text"
                outlined
              />
            </div>
          </div>
        </div>
      </div>
    </form>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
import WareHouseService from "@/services/WareHouseService";
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import InputNumber from "@/components/shared/inputs/InputNumber.vue";
import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useQuasar } from "quasar";

export default {
  name: "AcceptOrderDetails",
  components: {
    Mural,
    DefaultBtn,
    InputNumber,
  },
  setup() {
    let _wareHouseService = new WareHouseService();
    let breadcrumbs = ref(["Pedido para validação"]);
    let loading = ref(false);

    let route = new useRoute();
    let router = new useRouter();
    let $q = new useQuasar();

    let orderId = ref(null);

    let order = reactive({
      name: null,
      name_product: null,
    });

    onMounted(async () => {
      orderId.value = route.query.orderId;
      _getProviderProduct();
    });

    async function _getProviderProduct() {
      _showLoading();
      let response = await _wareHouseService.GetDataOfOrderForCancel(
        orderId.value
      );
      order.name = response.data.name;
      order.name_product = response.data.name_product;
      _hideLoading();
    }

    async function updateOrderSolicitation() {
      _showLoading();

      await _wareHouseService
        .acceptOrder(orderId.value)
        .then((response) => {
          loading.value = false;
          router.push({ name: "order-accept" });
        })
        .catch((error) => {
          $q.notify({
            message: error.response.data.toString(),
            color: "red-9",
            position: "top",
          });
          loading.value = false;
        });
    }

    function _showLoading() {
      loading.value = true;
    }

    function _hideLoading() {
      loading.value = false;
    }

    return {
      breadcrumbs,
      loading,
      order,
      updateOrderSolicitation,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .page-content {
    height: 85vh;
    overflow-y: auto;
  }
  .title-form {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    opacity: 0.45;
  }

  .content-new-training {
    height: 68vh;

    .content-thumb-training {
      height: 100%;
      .thumb-training {
        border-radius: 10px;
        .q-img {
          border-radius: 10px;
        }
      }
      .btn-thumb-training {
        .q-btn {
          text-transform: none !important;
          border-radius: 8px;
        }
      }
      .label-title-menus {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #686868;
      }
    }
    .content-form-training {
      height: 100%;

      .form-input {
        .title-input-form {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          opacity: 0.45;
        }
      }
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
}
</style>
